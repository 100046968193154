// @import '../../utils/variables';
$heightSliderSukcesu: 100vh;

.slidersukcesu.slidersukcesu--fade {
	// max-height: calc(100vh - 10rem);

	.carousel-item {
		height: 100vh;
		max-height: 100vh;

		img {
			opacity: 0.45;
		}
	}

	.carousel-caption {
		left: 0;
		right: 0;
		bottom: 50%;
		transform: translateY(50%);
	}

	.carousel-control-prev,
	.carousel-control-next {
		.material-icons {
			width: 6.5rem;
		}
	}

	.carousel-control-prev {
		justify-content: flex-end;
	}

	.carousel-control-next {
		justify-content: flex-start;
	}

}

#slider {
	position: relative;

	.slidersukcesu {
		z-index: 10;
		width: 100vw;
		height: $heightSliderSukcesu;

		@media (max-width: 1200px) {
			top: 0rem;
			height: calc(100vh - 64px);
		}

		.slide {
			&__item {
				height: 100%;
				background: #000000;
			}

			&__body {
				height: 100%;
				overflow: hidden;
				padding-right: 0;
				padding-left: 0;

				&--center {
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					@media (max-width: 767.98px) {
						margin-top: -4rem;
					}
				}
			}

			&__title {
				font-size: 8.9rem;
				letter-spacing: 0.03em;
				margin-bottom: 0;
				position: relative;
				max-height: 26rem;
				overflow: hidden;
				text-transform: none;
				font-weight: 400;
				line-height: 1.35;

				@media (max-width: 767.98px) {
					font-size: 4rem;
				}

				@media (max-height: 630px) {
					font-size: 4rem;
				}
			}

			&__description {
				color: white;
				font-size: 2rem;
			}

			&__button {
				color: black;
				margin-top: 6rem;
				position: absolute;
				top: 100%;
				transform: translateX(-50%);
				left: 50%;

				@media (max-width: 1200px) {
					padding: 2rem;
				}

				@media (min-width:320px) and (max-width:767px) {
					width: 90%;
				}

				@media (min-width:768px) and (max-width:1200px) {
					width: 45%;
				}

				@media (max-width: 359px) {
					white-space: normal;
				}
			}
		}
	}

	.carousel-control-prev {
		position: absolute !important;
		top: 0;
		left: 0;
	}
}

@media (min-width: 576px) {

	.carousel-control-prev,
	.carousel-control-next {
		width: calc((100% - 54rem)/2 + 6.5rem);
	}
}

@media (min-width: 768px) {

	.carousel-control-prev,
	.carousel-control-next {
		width: calc((100% - 72rem)/2 + 6.5rem);
	}
}

@media (min-width: 992px) {

	.carousel-control-prev,
	.carousel-control-next {
		width: calc((100% - 96rem)/2 + 6.5rem);
	}
}

@media (min-width: 1200px) {

	.carousel-control-prev,
	.carousel-control-next {
		width: calc((100% - 114rem)/2 + 6.5rem);
	}
}


#slider-123 {
	.carousel-indicators {
		bottom: calc(#{$heightSliderSukcesu} / 2 - 50vh + 4rem);

		transform: translateY(50%);
		margin-top: 50vh;
		width: 26rem;
		margin: auto;

		display: flex;
		justify-content: center;

		flex-direction: row;

		@media (max-width: 767.98px) {
			bottom: 3rem;
			width: 18rem;
		}

		li {
			height: .9rem;
			border-radius: 84px;
			flex-grow: 5;
			transition: 1s all ease-in-out;
			border: 0;
			margin-left: 1rem;
			margin-right: 1rem;

			&.active {
				flex-grow: 1;
			}
		}
	}

}

.mobile-slider {
	position: relative;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#000000, 0.56);
	}
}



.custom-slide {

	@media (min-width: 420px) and (max-width: 1023px) and (orientation: portrait) {

		&__mobile-hook {

			display: flex;

			flex-direction: column;

			align-items: center;

			justify-content: center;

			& .col-sm-4 {

				height: calc(100% / 3);

			}

		}

	}

	@media(min-width: 768px) and (max-width: 1023px) and (min-height: 1024px) {

		&__mobile-hook {

			flex-direction: row;

		}

		& .col-sm-4 {

			max-width: initial;

			height: 100% !important;

		}

		& .btn {

			width: 90% !important;

		}

	}

  & .carousel-caption {

    padding: 0;

  }

  &__slide {

    position: relative;

    display: flex;

    align-items: center;

    justify-content: center;

    height: 100%;

    background-size: cover;

	@media (max-width: 1024px) {

		background-position: center;

		align-items: flex-end;

	}

	@media (max-width: 820px) {

		padding-bottom: 80px;

	}

	@media (max-width: 500px) {

		padding-bottom: 50px;

	}

	@media (max-width: 340px) {

		padding-bottom: 10px;

	}

    @media (min-width: 1024px) {

      align-items: flex-end;

      padding-top: 0;

    }

    @media (min-width: 1024px) {

      padding-bottom: 157px;

    }

    & .btn {

		color: black;

		@media (max-width: 1440px) {

			font-size: 12px;

			width: 90%;

		}

		@media (max-width: 768px) and (min-height: 1024px) {

			width: 50%;

		}

		@media (max-width: 1279px) {

			font-size: 11px;

			padding: 1.5rem 1rem;

			margin-top: 0 !important;

		}

		@media(max-width: 1023px) and (orientation: landscape) {

			font-size: 8px;

			padding: 1.5rem .6rem;

		}

    }

    &--center {

		@media (max-width: 420px) {

			display: flex;

			flex-direction: column;

			align-items: center;

			justify-content: flex-end;

			& .btn {

				margin-bottom: 15px;

				&:last-of-type {

					margin-bottom: 50px;

				}

			}

		}

		@media (min-width: 1024px) {

			border: 6px solid black;

			border-top: none;

			border-bottom: none;

		}

	}

	&--last {

		@media (max-width: 374px) {

			padding-bottom: 40px;

		}

		@media (max-width: 768px) {

			background-position-y: 10% ;

		}

	}

    &::before {

      content: '';

      position: absolute;

      top: 0;

      left: 0;

      width: 100%;

      height: 100%;

      background-color: rgba(0, 0, 0, 0.5);

    }

  }

  &__title {

    position: absolute;

    z-index: 20;

    top: 50%;

    left: 50%;

    transform: translate(-50%, -80%);

    width: 100%;

    font-size: 70px;

    font-weight: bold;

    color: white;

    text-transform: none;

    line-height: 84px;

    @media (max-width: 576px) {

      line-height: 44px;

      font-weight: normal;

	}

	@media (max-width: 420px) {

		transform: translate(-50%, -140%);

		font-size: 25px;

	}

	@media(max-width: 1023px) and (orientation: landscape) {

		font-size: 40px;

	}

  }

}

